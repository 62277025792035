.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cell-green {
  text-align: center;
  font-weight: bold;
  background-color: #00e600;
}

.cell-amber {
  text-align: center;
  font-weight: bold;
  background-color: #ced0d2;
}

.cell-red {
  text-align: center;
  font-weight: bold;
  background-color: #ff8080;
}

.gridDiv {
  height: calc(100vh - 100px);
  width: 100%;
}

.arsenal-drew {
  background-color: #ced0d2;
}

.arsenal-won {
  background-color: #00e600;
}

.arsenal-lost {
  background-color: #ff8080;
}

.ag-cell {
  padding: 0 !important;
}

.prediction-item {
  display: flex;
  justify-content: space-around;
}

.prediction-item p {
  width: 25%;
  text-align: center;
}

.prediction-item input {
  width: 25%;
}

.FT {
  background: lightgray;
}

.PST {
  background: lightyellow;
}

.LIVE {
  background: lightgreen;
}